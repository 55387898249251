<template>
  <div class="prompts-list">
    <spinner v-if="loading" />
    <ul v-else>
      <span>
        <h5 class="prompt-category-header" v-if="getUserPrompts.length > 0">
          Create Your Own
        </h5>
        <PromptListItem
          v-for="item in getUserPrompts"
          :key="item.ID"
          :item="item"
          :dq="false"
          :edit="true"
        />
      </span>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromptListItem from "./PromptListItem";
import PromptEditorModal from "./PromptEditorModal.vue";
import Spinner from "./Spinner.vue";

export default {
  components: { PromptListItem, Spinner },
  props: {
    module: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["getUserPrompts"]),
  },
  methods: {
    showPopup() {
      this.$modal.show(
        PromptEditorModal,
        { item: this.item },
        {
          name: "modal-" + this.item.ID,
        }
      );
    },
  },
  created() {
    this.$store.dispatch("loadUserPrompts", [
      this.module,
      () => {
        this.loading = false;
      },
    ]);
  },
  mounted() {},
};
</script>

<style scoped>
.prompt {
  cursor: pointer;
  color: #000;
}
.prompt:hover {
  text-decoration: underline;
}
</style>