<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <PromptAddForm :module="module" />
      <PromptCurrentUser :module="module" />
    </div>
  </div>
</template>

<script>
import PromptAddForm from "./PromptAddForm.vue";
import PromptCurrentUser from "./PromptCurrentUser.vue";
import Spinner from "./Spinner.vue";

export default {
  components: {
    PromptAddForm,
    PromptCurrentUser,
    Spinner,
  },
  props: {
    module: {
      type: String,
      default: "1",
    },
    page_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [
        "default_font_family",
        "default_font_size",
        "image_upload_limit",
        "image_upload_count",
      ],
      () => {
        this.loading = false;
      },
    ]);
  },
};
</script>
