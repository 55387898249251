<template>
  <div class="row mt-3 mb-4">
    <div class="offset-md-1 col-md-10">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Add Your Own Prompt"
          aria-label="Add Your Own Prompt"
          aria-describedby="basic-addon2"
          v-model="prompt"
          @keyup="validateText"
          :disabled="buttonClicked"
        />
        <div class="input-group-append">
          <button
            @click="addNewPrompt"
            class="btn btn-primary blue-btn"
            type="button"
            :disabled="!buttonActive"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    module: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      prompt: "",
      buttonActive: false,
      buttonClicked: false,
    };
  },
  computed: {},
  methods: {
    validateText(evt) {
      if (this.prompt.trim().length > 0) {
        this.buttonActive = true;
        if (evt.code === `Enter`) {
          this.addNewPrompt();
        }
      } else {
        this.buttonActive = false;
      }
    },
    addNewPrompt() {
      if (this.prompt.length > 0) {
        this.buttonActive = false;
        this.buttonClicked = true;
        this.submitNewPrompt();
      }
    },
    submitNewPrompt() {
      this.$store.dispatch("addUserPrompt", [
        this.module,
        this.prompt,
        () => {
          this.prompt = "";
          this.buttonClicked = false;
          this.$toast.open({
            message: "Prompt Successfully Added!",
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
};
</script>
