<template>
  <div class="container height-100">
    <CloseModalButton @close="closeModal" />
    <PromptDeeperQuestions v-if="dq" :prompt="prompt" />
    <div class="page-editor-modal row height-100">
      <div class="col-md-12 pt-3 pb-3 height-100">
        <button
          class="btn btn-success btn-sm btn-save"
          v-if="show_save"
          @click="saveChanges"
        >
          <i class="fa fa-save"></i> Save
        </button>
        <div class="card height-100">
          <input
            type="text"
            class="form-control mb-2"
            :value="postTitle"
            @input="triggerUpdate"
            :disabled="true"
          />

          <CKCustomEditorPrompt
            :title="false"
            :item="item"
            @triggerUpdate="triggerUpdate"
            ref="editor"
            :scale="1"
            :prompt="prompt"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton";
import CKCustomEditorPrompt from "./CKCustomEditorPrompt";
import PromptDeeperQuestions from "./PromptDeeperQuestions.vue";

export default {
  components: {
    CloseModalButton,
    CKCustomEditorPrompt,
    PromptDeeperQuestions,
  },
  data() {
    return {
      active_view: "content",
      timer: {
        type: Object,
        default: 0,
      },
      show_save: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          post_title: "",
          post_content: "",
        };
      },
    },
    prompt: {
      type: Object,
      default() {
        return {
          ID: 0,
          post_title: "",
          post_content: "",
        };
      },
    },
    dq: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    postTitle: {
      get() {
        return this.item.post_title;
      },
      set(value) {
        this.item.post_title = value;
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveChanges() {
      clearTimeout(this.timer);

      this.show_save = false;

      if (this.item?.ID) {
        this.$store.dispatch("updatePromptWriting", [
          this.item.ID,
          this.item.post_title,
          this.item.post_content,
          ({ message, stripped_title }) => {
            this.item.stripped_title = stripped_title;
            this.$toast.open({
              message,
              position: "bottom-left",
              type: "success",
            });
          },
        ]);
      } else {
        this.$store.dispatch("savePromptWriting", [
          this.prompt.ID,
          this.item.post_title,
          this.item.post_content,
          this.$root.module,
          ({ message, stripped_title }) => {
            this.item.stripped_title = stripped_title;
            this.$toast.open({
              message,
              position: "bottom-left",
              type: "success",
            });
          },
        ]);
      }
    },
    triggerUpdate() {
      clearTimeout(this.timer);
      this.show_save = true;
      this.timer = setTimeout(() => {
        this.saveChanges();
      }, 5000);
    },
  },
  mounted() {
    this.postTitle =
      this.item.post_title !== ""
        ? this.item.post_title
        : this.prompt.post_title;
  },
};
</script>
<style scoped>
.card {
  border: none;
}
.btn-save {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
}
.card-header > p,
.card-body > p {
  margin-bottom: 0;
}
</style>