<template>
  <div>
    <li class="prompt-option">
      <span class="prompt" @click="showPopup">{{ item.post_title }}</span>
      <span class="edit-prompt" v-if="edit" @click="triggerEditTite"
        ><i class="far fa-edit"></i
      ></span>
    </li>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromptEditorModal from "./PromptEditorModal.vue";
import PromptTitleEditModal from "./PromptTitleEditModal.vue";

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          post_title: "",
        };
      },
    },
    dq: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      writingItem: {
        type: Object,
        default: () => {
          return {
            ID: null,
            post_title: "",
            post_content: "",
          };
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getPromptWritings"]),
  },
  methods: {
    showPopup() {
      this.loadPromptWriting(() => {
        this.$modal.show(
          PromptEditorModal,
          {
            item: this.writingItem,
            prompt: this.item,
            dq: this.dq,
          },
          {
            name: "modal-prompt",
            resizable: true,
            minWidth: 880,
            minHeight: 605,
          }
        );
      });
    },
    triggerEditTite() {
      if (!this.edit) return;
      this.$modal.show(
        PromptTitleEditModal,
        {
          prompt: this.item,
        },
        {
          name: "modal-prompt",
          width: "55%",
          height: "auto",
        }
      );
    },
    loadPromptWriting(callback) {
      this.$store.dispatch("loadPromptWriting", [
        this.item.ID,
        ({ items: [writingItem] }) => {
          this.writingItem = writingItem;
          callback();
        },
      ]);
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style scoped>
.prompt {
  cursor: pointer;
  color: #000;
}
.prompt:hover {
  text-decoration: underline;
}
.prompt-option:hover {
  background-color: rgba(204, 204, 204, 0.3);
}
.edit-prompt {
  float: right;
  cursor: pointer;
  display: none;
}
.prompt-option:hover > .edit-prompt {
  display: block;
}
</style>