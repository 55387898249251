<template>
  <div class="card-body">
    <CloseModalButton @close="closeModal" />
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="Prompt Title"
        aria-label="Prompt Title"
        v-model="promptTitle"
        :class="notEmpty ? 'is-valid' : 'is-invalid'"
      />
      <div class="input-group-append">
        <span
          class="btn"
          :class="notEmpty ? 'btn-success' : 'btn-danger disabled'"
          @click="sendNow"
          >Save</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton.vue";

export default {
  components: { CloseModalButton },
  props: {
    prompt: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    notEmpty() {
      return this.promptTitle.length > 0;
    },
    promptTitle: {
      get() {
        return this.prompt.post_title;
      },
      set(value) {
        this.prompt.post_title = value;
      },
    },
  },
  methods: {
    sendNow() {
      if (this.notEmpty) {
        this.$store.dispatch("updatePromptTitle", [
          this.prompt.ID,
          this.prompt.post_title,
          ({ message }) => {
            this.$toast.open({
              message,
              position: "bottom-left",
              type: "success",
            });
            this.closeModal();
          },
        ]);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>
