<template>
  <div class="deeper-questions-container" id="deeper-questions" v-if="!loading">
    <h5>Deeper Questions to Consider:</h5>
    <div class="questions-body">
      <ul>
        <li
          v-for="question in cleanQuestions"
          :key="question.value"
          class="deeper-question"
          :data-value="question.value"
        >
          {{ question.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    prompt: {
      type: Object,
      default() {
        return {
          post_title: "",
          post_content: "",
        };
      },
    },
  },
  data() {
    return {
      questions: [],
      loading: true,
    };
  },
  computed: {
    cleanQuestions() {
      return this.questions.map((item) => {
        item.label = item.label.substring(item.label.indexOf(".") + 1).trim();
        return item;
      });
    },
  },
  mounted() {
    this.$store.dispatch("loadDeeperQuestions", [
      this.prompt.ID,
      ({ items }) => {
        this.questions = items;
        this.loading = false;
      },
    ]);
  },
};
</script>

<style scoped>
#deeper-questions {
  z-index: 9999;
}
#deeper-questions:hover {
  right: -130px;
}
</style>